<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" />
          Genel Bilgiler
        </template>
        <b-card no-body>
          <b-card-body>
            <b-overlay
              :show="dataItemLoading"
              rounded="sm"
              :variant="variant"
              :opacity="opacity"
              :blur="blur"
            >
              <b-row>
                <b-col>
                  <insurance-call-view-header :new-modal="showModal" />
                </b-col>
              </b-row>
            </b-overlay>
          </b-card-body>
          <b-alert
            v-if="dataList.length < 1"
            show
            variant="info"
            class="m-2"
          >
            <div class="alert-body text-center">
              Hiç kayıt bulunmuyor 👏
              <div class="mt-2">
                <b-button
                  variant="primary"
                  size="sm"
                  class="mb-1"
                  @click="showModal"
                >
                  <feather-icon icon="PlusIcon" />
                  Yeni durum
                </b-button>
              </div>
            </div>
          </b-alert>
          <template v-else>
            <b-table
              responsive="sm"
              :fields="fields"
              :items="dataList"
              striped
            >
              <template #cell(insurance_call_status)="data">
                <b-badge :variant="data.item.variant">
                  <feather-icon :icon="data.item.icon" />
                  {{ data.item.insurance_call_status }}
                </b-badge>
              </template>
              <template #cell(username)="data">
                <b>{{ data.item.username }}</b>
                <div>
                  <small class="text-secondary"><feather-icon icon="SaveIcon" /> {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
                </div>
              </template>
            </b-table>
            <b-card-footer>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataCounts"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-card-footer>
          </template>
        </b-card>
        <b-modal
          v-model="modal.status"
          title="Yeni Durum Oluştur"
          centered
          size="lg"
          no-close-on-backdrop
          no-close-on-esc
        >
          <validation-observer ref="simpleRules">
            <insurance-call-lines-item-form />
          </validation-observer>
          <template #modal-footer>
            <b-button
              variant="secondary"
              @click="modal.status=false"
            >
              İptal
            </b-button>
            <b-button
              variant="success"
              :disabled="modal.saving"
              @click="saveModalData"
            >
              <feather-icon
                v-if="!modal.saving"
                icon="SaveIcon"
              />
              <b-spinner
                v-if="modal.saving"
                small
              />
              Kaydet
            </b-button>
          </template>
        </b-modal>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Poliçe Bilgileri</span>
        </template>
        <insurance-call-items-card />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BAlert, BModal, BSpinner, BTabs, BTab, BRow, BCol, BBadge, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, localize } from 'vee-validate'
import Customer from '@/views/Crm/View/Customer.vue'
import InsuranceCallLinesItemForm from '@/views/Crm/InsuranceCall/Components/ItemForm.vue'
import InsuranceCallViewHeader from '@/views/Crm/InsuranceCall/Components/viewHeader.vue'
import InsuranceCallItemsCard from '@/views/Crm/InsuranceCall/Components/ItemsCard.vue'

const tableName = 'com_insurance_call_lines'
export default {
  name: 'InsuranceCallLines',
  components: {
    InsuranceCallItemsCard,
    InsuranceCallViewHeader,
    InsuranceCallLinesItemForm,
    Customer,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BAlert,
    BModal,
    BSpinner,
    BTabs,
    BTab,
    ValidationObserver,
    BRow,
    BCol,
    BBadge,
    BOverlay,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
      fields: [
        {
          key: 'content',
          label: 'İçerik',
          thClass: 'width-400 text-nowrap',
          tdClass: 'width-400 text-nowrap',
        },
        {
          key: 'insurance_call_status',
          label: 'Durum',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },

        {
          key: 'username',
          label: 'Danışman',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.content AS content`,
          `${tableName}.created AS created`,
          `${tableName}.id_com_insurance_call_status AS id_com_insurance_call_status`,
          `${tableName}.calldate AS calldate`,
          'com_user.name AS username',
          'com_insurance_call_status.title AS insurance_call_status',
          'com_insurance_call_status.status AS status',
          'com_insurance_call_status.variant AS variant',
          'com_insurance_call_status.icon AS icon',
        ],
        where: {},
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['insuranceCall/dataItem']
    },
    modalData() {
      return this.$store.getters['insuranceCallLines/dataItem']
    },
    dataList() {
      return this.$store.getters['insuranceCallLines/dataList']
    },
    dataCounts() {
      return this.$store.getters['insuranceCallLines/dataCounts']
    },
    saveData() {
      return this.$store.getters['insuranceCallLines/dataSaveStatus']
    },
    dataLoading() {
      return this.$store.getters['insuranceCallLines/dataLoading']
    },
    modal() {
      return this.$store.getters['insuranceCallLines/modalData']
    },
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    dataItemLoading() {
      return this.$store.getters['insuranceCall/dataLoading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
    dataItem(val) {
      this.getCustomer(val.id_com_customer)
    },
  },
  created() {
    this.getDataList()
    this.getDataItem()
    localize('tr')
  },
  methods: {
    getDataItem() {
      this.$store.dispatch('insuranceCall/getDataItem', this.$route.params.id)
    },
    getDataList() {
      this.dataQuery.where.id_com_insurance_call = this.$route.params.id
      this.$store.dispatch('insuranceCallLines/getDataList', this.dataQuery)
    },
    showModal() {
      this.modal.status = true
      this.$store.commit('insuranceCallLines/RESET_DATA_ITEM')
    },
    saveModalData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.modal.saving = true
          this.modalData.id_com_insurance_call = this.$route.params.id
          this.$store.dispatch('insuranceCallLines/saveData', this.modalData)
            .then(response => {
              if (response.status) {
                this.modal.status = false
                this.getDataItem()
              }
              this.modal.saving = false
            })
        }
      })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
