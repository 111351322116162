<template>
  <b-form-group
    label="İçerik"
    label-for="content"
  >
    <b-form-textarea
      id="content"
      v-model="dataItem.content"
      placeholder="İçerik"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'InsuranceCallLinesContentCard',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    dataItem() {
      return this.$store.getters['insuranceCallLines/dataItem']
    },
  },
}
</script>
