<template>
  <div>
    <b-form-group
      label="Hatırlatma Oluştur"
      label-for="alert"
    >
      <b-form-checkbox
        id="alert"
        v-model="itemData.alert"
        value="1"
        switch
        :disabled="!itemData.calldate && !itemData.interview"
      >
        {{ itemData.alert === '1'? 'Aktif' : 'Pasif' }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'InsuranceCallLinesAlertCard',
  components: { BFormGroup, BFormCheckbox },
  computed: {
    itemData() {
      return this.$store.getters['insuranceCallLines/dataItem']
    },
  },
}
</script>
