<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-card-title>Poliçe Listesi</b-card-title>
      </b-card-body>
      <template v-if="dataLoading">
        <loading v-if="dataLoading" />
      </template>
      <template v-else>
        <template v-if="dataList.length > 0">
          <b-table
            responsive
            :fields="fields"
            :items="dataList"
            striped
            small
            class="font-small-2"
          >
            <template #cell(brand)="data">
              {{ data.item.brand ? data.item.brand : data.item.swap_brand ? data.item.swap_brand : '-' }}
            </template>
            <template #cell(model)="data">
              {{ data.item.model ? data.item.model : data.item.swap_model ? data.item.swap_model : '-' }}
            </template>
            <template #cell(sdate)="data">
              {{ moment(data.item.sdate).format('DD.MM.YYYY') }}
            </template>
            <template #cell(edate)="data">
              {{ moment(data.item.edate).format('DD.MM.YYYY') }}
            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCounts"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </template>
        <template v-else>
          <b-alert
            show
            variant="info"
            class="m-2"
          >
            <div class="alert-body text-center">
              Kayıtlı veri bulunmuyor.
            </div>
          </b-alert>
        </template>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardFooter, BTable, BPagination, BCardBody, BCardTitle, BAlert,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'InsuranceCallItemsCard',
  components: {
    Loading,
    BCard,
    BCardFooter,
    BTable,
    BPagination,
    BCardBody,
    BCardTitle,
    BAlert,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'police_no',
          label: 'Police No',
        },
        {
          key: 'account_no',
          label: 'Hesap No',
        },
        {
          key: 'insurance_company',
          label: 'Sigorta Şirketi',
        },
        {
          key: 'title',
          label: 'Branş',
        },
        {
          key: 'license_plate',
          label: 'Plaka',
        },
        {
          key: 'brand',
          label: 'Marka',
        },
        {
          key: 'model',
          label: 'Model',
        },

        {
          key: 'sdate',
          label: 'Başlangıç Tarihi',
        },
        {
          key: 'edate',
          label: 'Bitiş Tarihi',
        },
      ],
      dataQuery: {
        where: {},
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['insuranceCall/dataItem']
    },
    dataList() {
      return this.$store.getters['insuranceCallItems/dataList']
    },
    dataCounts() {
      return this.$store.getters['insuranceCallItems/dataCounts']
    },
    dataLoading() {
      return this.$store.getters['insuranceCallItems/dataLoading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.dataQuery.where['com_insurance_call_items.id_com_insurance_call'] = this.$route.params.id
      this.$store.dispatch('insuranceCallItems/getDataList', this.dataQuery)
    },
  },
}
</script>
